import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import parse from "html-react-parser";

// Patterns
import PatternFirst from "../images/pattern_01.png";

// markup
const NotFoundPage = ({ data: { page, header, forms, footer, socials, copyright, user, cookies } }) => {
  const pageContent = page.nodes[0].page404;
  forms = forms.nodes[0]

  return (
    <Layout
      footer={footer.nodes}
      header={header.nodes}
      forms={forms}
      user={user.nodes}
      cookies={cookies.nodes}
      copyright={copyright.nodes}
      socials={socials.nodes[0].socialNetworks}
      locale={page.nodes[0].locale.locale}
      uri={page.nodes[0].uri}
    >
      <SEO title={page.nodes[0].seo.title} description={page.nodes[0].seo.metaDesc} />
      <div className="p404">
        <b>{parse(pageContent.error404Code)}</b>
        <h1>{parse(pageContent.error404Message)}</h1>
        <p>{parse(pageContent.error404ExtMessage)}</p>
        <div className="pattern">
          <img src={PatternFirst} alt="Pattern" />
        </div>
      </div>
    </Layout>
  );
};

export default NotFoundPage;

export const query = graphql`
  {
    copyright: allWpRepeatableBlocks(filter: { slug: { eq: "copyrights" } }) {
      nodes {
        slug
        title
        blockCopyrights {
          copyrightTextAfter
          copyrightTextBefore
          copyrightTextMiddle
          userAgreement {
            linkText
          }
          cookiesPolicy {
            linkText
          }
        }
        uri
        locale {
          locale
        }
      }
    }
    user: allWpRepeatableBlocks(
      filter: { slug: { eq: "ugoda-korystuvacha" } }
    ) {
      nodes {
        slug
        title
        uri
        locale {
          locale
        }
        blockUserAgreement {
          agreementBlockId
          userAgreementText
          userAgreementButtonText
          userAgreementTitle
        }
      }
    }
    cookies: allWpRepeatableBlocks(
      filter: { slug: { eq: "polityka-cookies" } }
    ) {
      nodes {
        slug
        title
        uri
        locale {
          locale
        }
        blockUserAgreement {
          agreementBlockId
          userAgreementText
          userAgreementButtonText
          userAgreementTitle
        }
      }
    }
    page: allWpRepeatableBlocks(
      filter: {
        locale: { locale: { eq: "uk" } }
        slug: { eq: "storinka-pomylok-404" }
      }
    ) {
      nodes {
        page404 {
          error404Code
          error404ExtMessage
          error404Message
        }
        slug
        locale {
          locale
        }
        seo {
          title
          metaDesc
        }
        uri
        title
      }
    }
    footer: allWpRepeatableBlocks(filter: { slug: { eq: "footer" } }) {
      nodes {
        mainFooter {
          copyrightText
          footerLogo
          orderButtonIcon
          orderButtonText
          centrColumnItems {
            item
            name
          }
          leftColumnItems {
            name
            item
          }
          rightColumnItems {
            item
            name
          }
        }
        slug
        title
        locale {
          locale
        }
      }
    }
    header: allWpRepeatableBlocks(filter: { slug: { eq: "header" } }) {
      nodes {
        slug
        title
        locale {
          locale
        }
        mainHeader {
          headerLogo
          headerSubmenu {
            dropdownBtnIcon
            dropdownPages {
              pageTitle
              pageLink
            }
          }
          menuCallbackBtn {
            icon
            text
          }
          menuOrderHouseBtn {
            icon
            link
            text
          }
          headerMenuFix {
            pageLink
            pageTitle
          }
          headerMessengerMenu {
            messengerList {
              icon
              link
            }
            dropdownBtnIcon
          }
        }
      }
    }
    forms: allWpRepeatableBlocks(
      filter: { locale: { locale: { eq: "uk" } }, slug: { eq: "formy" } }
    ) {
      nodes {
        formSettings {
          contactFormCommentPlaceholder
          contactFormEmailPlaceholder
          contactFormSendButton
          contactFormTitle
          invalidMailMsg
          invalidPhoneMsg
          modalCallbackButtonText
          modalCallbackTitle
          modalThanksButtonText
          modalThanksText
          modalThanksTitle
          modalViewingButtonText
          modalViewingFormTitle
          viewingFormDatetime
          viewingFormSendButton
          viewingFormTitle
          viewingFormUsername
        }
      }
    }
    socials: allWpRepeatableBlocks(
      filter: {
        locale: { locale: { eq: "uk" } }
        slug: { eq: "soczialni-merezhi" }
      }
    ) {
      nodes {
        slug
        title
        locale {
          locale
        }
        socialNetworks {
          socialNetworks {
            icon
            link
            title
          }
        }
      }
    }
  }
`;
